<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col>
          <filter-card :get-data-list="getDataList" />
        </b-col>
        <b-col cols="auto">
          <b-button
            variant="primary"
            to="/admin/tasks/customer-search"
          >
            <FeatherIcon icon="PlusIcon" /> Oluştur
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
    >
      <template #cell(title)="data">
        <b-link
          :to="'/admin/tasks/view/' + data.item.id"
          class="text-body"
        >
          <div>
            {{ data.item.title }}
          </div>
          <div class="text-danger font-small-2">
            <div v-if="data.item.deadline">
              <strong>Tesim Tarihi:</strong> {{ moment(data.item.deadline).format('ll') }}
            </div>
          </div>
        </b-link>
      </template>
      <template #cell(company)="data">
        <div>{{ data.item.project }}</div>
        <div class="font-small-2 text-primary">
          {{ data.item.company }}
        </div>
      </template>
      <template #cell(priority)="data">
        <b-badge
          v-if="data.item.id_priorities === '1'"
          variant="light-danger"
          class="w-75"
        >
          {{ data.item.priority }}
        </b-badge>
        <b-badge
          v-if="data.item.id_priorities === '2'"
          variant="light-info"
          class="w-75"
        >
          {{ data.item.priority }}
        </b-badge>
        <b-badge
          v-if="data.item.id_priorities === '3'"
          variant="light-secondary"
          class="w-75"
        >
          {{ data.item.priority }}
        </b-badge>
      </template>
      <template #cell(task_status)="data">
        <b-badge
          v-if="data.item.id_task_statuses === '1'"
          variant="warning"
        >
          {{ data.item.task_status }}
        </b-badge>
        <b-badge
          v-if="data.item.id_task_statuses === '2'"
          variant="info"
        >
          {{ data.item.task_status }}
        </b-badge>
        <b-badge
          v-if="data.item.id_task_statuses === '3'"
          variant="secondary"
        >
          {{ data.item.task_status }}
        </b-badge>
        <b-badge
          v-if="data.item.id_task_statuses === '4'"
          variant="danger"
        >
          {{ data.item.task_status }}
        </b-badge>
        <b-badge
          v-if="data.item.id_task_statuses === '5'"
          variant="success"
        >
          {{ data.item.task_status }}
        </b-badge>
      </template>
      <template #cell(status)="data">
        <FeatherIcon
          :class="data.item.status === '1'? 'text-success' : 'text-warning'"
          :icon="data.item.status === '1'? 'CheckIcon' : 'ClockIcon'"
        />
      </template>
      <template #cell(control)="data">
        <b-button
          variant="primary"
          size="sm"
          :to="'/admin/tasks/view/' + data.item.id"
        >
          <FeatherIcon icon="EyeIcon" />
          Görüntüle
        </b-button>
      </template>
    </b-table>
    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        :total-rows="dataCounts"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </b-card-footer>
  </b-card>
</template>
<script>
import {
  BCard, BCardBody, BTable, BPagination, BButton, BCardFooter, BBadge, BRow, BCol, BLink,
} from 'bootstrap-vue'
import FilterCard from '@/views/Admin/Tasks/FilterCard.vue'

export default {
  name: 'TaskIndex',
  components: {
    BCard,
    BCardBody,
    BTable,
    BPagination,
    BButton,
    BCardFooter,
    BBadge,
    BRow,
    BCol,
    BLink,
    FilterCard,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      fields: [
        {
          key: 'title',
          label: 'GÖREV ADI',
        },
        {
          key: 'priority',
          label: 'ÖNCELİK',
          thClass: 'width-200 text-nowrap text-center',
          tdClass: 'width-200 text-nowrap text-center',
        },
        {
          key: 'company',
          label: 'İLGİLİ PROJE',
          thClass: 'width-300 text-nowrap',
          tdClass: 'width-300 text-nowrap',
        },
        {
          key: 'task_status',
          label: 'DURUM',
          thClass: 'width-200 text-nowrap',
          tdClass: 'width-200 text-nowrap',
        },
        {
          key: 'control',
          label: '',
          thClass: 'text-right width-200 text-nowrap',
          tdClass: 'text-right width-200 text-nowrap',
        },
      ],
      dataQuery: {
        limit: 20,
        start: 0,
      },
    }
  },
  computed: {
    userData() {
      return JSON.parse(localStorage.getItem('userData'))
    },
    dataList() {
      return this.$store.getters['tasks/userTasks']
    },
    dataCounts() {
      return this.$store.getters['tasks/dataCounts']
    },
    filterData() {
      return this.$store.getters['tasks/filterData']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * 20
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      if (this.filterData.keyword) {
        this.dataQuery.or_like = {
          'customers.company': this.filterData.keyword,
          'projects.title': this.filterData.keyword,
          'tasks.title': this.filterData.keyword,
        }
      } else {
        this.dataQuery.or_like = {}
      }
      this.dataQuery.where_in = []
      if (this.filterData.id_task_statuses.length) {
        this.dataQuery.where_in.push(['tasks.id_task_statuses', this.filterData.id_task_statuses])
      }
      if (this.filterData.id_priorities.length) {
        this.dataQuery.where_in.push(['tasks.id_priorities', this.filterData.id_priorities])
      }
      this.dataQuery.where = {
        'task_users.id_assigned': this.userData.id,
      }
      this.$store.dispatch('tasks/getUserTasks', this.dataQuery)
    },
  },
}
</script>
